.BrandSoulSlider {
    .line-bottom {
        @apply relative;
        &::after {
            content: "";
            @apply absolute bottom-0 left-0 w-screen h-px bg-current;
        }
    }

    .wysiwyg {
        ul > li {
            @apply list-none ml-0 flex items-start gap-x-1;

            &::before {
                content: "";

                @apply h-px bg-current w-3 shrink-0 mt-3 inline-block;
            }
        }

        p {
            @apply dark:text-white;
        }
    }
}
