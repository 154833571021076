.swiper.SliderImageGrid {
    .swiper-slide:nth-child(odd) {
        @apply lg:aspect-5/4 lg:w-[calc(55%-11px)] xxl:w-[calc(55%-17px)] xxxl:w-[calc(55%-22px)];
    }

    .swiper-slide:nth-child(even) {
        @apply lg:aspect-5/4 lg:w-[calc(45%-11px)] xxl:w-[calc(45%-17px)] xxxl:w-[calc(45%-22px)];
    }

    .swiper-slide img {
        scale: 1.2 !important;
    }
}

.SliderImageGrid {
    .pimcore_editable_block {
        @apply grid grid-cols-1 gap-2 max-w-md md:max-w-none md:grid-cols-2 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-8  xxxl:grid-cols-4;
    }
}
