.Table {
    @media (min-width: theme("screens.md")) {
        .column-headline-row {
            @apply relative;

            &::after {
                content: "";

                @apply absolute bottom-2 left-0 right-0 h-px bg-black dark:bg-white;
            }
        }

        td,
        th {
            width: var(--column-width);
        }
    }
}
