@layer components {
    .button,
    .button-icon,
    .pill {
        @apply relative px-8 py-3 text-15 font-monument bg-black dark:bg-white text-white dark:text-black text-center uppercase rounded-full z-10 transition-transform duration-200 md:px-8 md:py-3.5 md:text-16 lg:py-4.5 xl:text-17 xxl:py-5 xxxl:text-18 xxxl:py-5.5;

        &:hover {
            transform: translate(2px, -4px) !important;
        }

        &::before {
            content: "";
            @apply absolute inset-0 bg-black/65 dark:bg-white/80 rounded-full -z-10 transition-transform duration-200;

            &:hover {
                @apply -translate-x-0.5 translate-y-1;
            }
        }

        &-dark {
            @apply relative px-8 py-3 text-15 font-monument bg-black text-white text-center uppercase rounded-full z-10 transition-transform duration-200 md:px-8 md:py-3.5 md:text-16 lg:py-4.5 xl:text-17 xxl:py-5 xxxl:text-18 xxxl:py-5.5;

            &:hover {
                transform: translate(2px, -4px) !important;
            }

            &::before {
                content: "";
                @apply absolute inset-0 bg-black/65 rounded-full -z-10 transition-transform duration-200;

                &:hover {
                    @apply -translate-x-0.5 translate-y-1;
                }
            }
        }

        &-inverted {
            @extend .button;
            @apply bg-white dark:bg-black text-black dark:text-white;

            &::before {
                @apply absolute inset-0 bg-white/80 dark:bg-black/65 rounded-full -z-10 transition-transform duration-200;
            }
        }

        &-outlined {
            @extend .button;
            @apply text-black dark:text-white bg-transparent border border-black dark:border-white lg:border-2;

            &::before {
                @apply hidden;
            }

            &:hover {
                transform: translate(0, 0) !important;
            }
        }
    }

    .button-social {
        @apply px-2 h-8.5 grid place-items-center font-monument border dark:border-white rounded-full lg:border-2;

        &-dark {
            @extend .button-social;
            border-color: black;
            color: black;
        }

        svg {
            @apply w-full aspect-square;
        }
    }

    .button-social-round {
        @apply h-8.5 aspect-square grid place-items-center font-monument border dark:border-white rounded-full lg:border-2;

        &-dark {
            @extend .button-social-round;
            border-color: black;
            color: black;

            svg {
                @apply w-full aspect-square;
            }
        }

        svg {
            @apply w-full aspect-square;
        }
    }

    .button-social-no-border {
        @apply h-8.5 aspect-square grid place-items-center font-monument;

        &-dark {
            @extend .button-social-no-border;
            color: black;

            svg {
                @apply w-full aspect-square p-0;
            }
        }

        svg {
            @apply w-full aspect-square p-0;
        }
    }

    // .button-icon {
    //     @apply h-[50px] aspect-square grid place-items-center bg-black dark:bg-white text-black rounded-full md:h-[55px] lg:h-[60px] xl:h-[65px] xxxl:h-[70px];
    // }

    .button-video {
        &::before {
            @apply bg-black dark:bg-white;
        }
    }

    .button-menu, .button-menu-filled {
        &[aria-expanded="false"] {
            &:hover {
                transform: translate(2px, -3px) !important;
            }

            &::before {
                content: "";
                @apply absolute inset-0 bg-black/65 dark:bg-white/80 rounded-full -z-10 transition-transform duration-200;

                &:hover {
                    @apply -translate-x-0.5 translate-y-[3px];
                }
            }
        }
    }

    .button-menu {
        @apply relative transition-transform duration-200;
        &[aria-expanded="true"] {
            @apply bg-transparent;

            span {
                @apply bg-black;
            }
        }

        &-filled[aria-expanded="true"] {
            @apply bg-black dark:bg-white;
        }
    }
}
