.desktop-menu {
    .pimcore_editable_block[data-name="columns"] {
        @apply grid grid-cols-6 gap-3;
    }
}

.AB-Image {
    .pimcore_editable_image,
    img {
        @apply w-full h-full;
    }

    img {
        @apply object-cover object-center;
    }
}
