.ServiceClickMap {
    .animate-ellipse {
        @apply absolute border-2 border-current;

        left: 7.3%;
        right: 7.5%;
        top: 50%;
        height: 0%;
        transform: translateY(-50%);
        border-radius: 100%;

        &:nth-of-type(1) {
            animation: ellipse-mobile-1 5s ease-in-out infinite both;
        }

        &:nth-of-type(2) {
            animation: ellipse-mobile-2 5s ease-in-out infinite both;
        }

        &:nth-of-type(3) {
            animation: ellipse-mobile-3 5s ease-in-out infinite both;
        }

        @media (min-width: theme("screens.md")) {
            left: 3.9%;
            right: 4.2%;
            top: 50%;

            &:nth-of-type(1) {
                animation: ellipse-desktop-1 5s ease-in-out infinite both;
            }
            
            &:nth-of-type(2) {
                animation: ellipse-desktop-2 5s ease-in-out infinite both;
            }
            
            &:nth-of-type(3) {
                animation: ellipse-desktop-3 5s ease-in-out infinite both;
            }
        }
    }

    @keyframes ellipse-desktop-1 {
        0% {
            height: 0;
        }

        20% {
            height: 0;
        }

        30% {
            height: 82.5%;
        }

        55% {
            height: 82.5%;
        }

        100% {
            height: 0;
        }
    }

    @keyframes ellipse-desktop-2 {
        0% {
            height: 0;
        }

        20% {
            height: 0;
        }

        35% {
            height: 82.5%;
        }

        55% {
            height: 82.5%;
        }

        70% {
            height: 82.5%;
        }

        95% {
            height: 0;
        }

        100% {
            height: 0;
        }
    }

    @keyframes ellipse-desktop-3 {
        0% {
            height: 0;
        }

        20% {
            height: 0;
        }

        40% {
            height: 82.5%;
        }

        55% {
            height: 82.5%;
        }

        80% {
            height: 82.5%;
        }

        90% {
            height: 0;
        }

        100% {
            height: 0;
        }
    }

    @keyframes ellipse-mobile-1 {
        0% {
            height: 0;
        }

        20% {
            height: 0;
        }

        30% {
            height: 69%;
        }

        55% {
            height: 69%;
        }

        100% {
            height: 0;
        }
    }

    @keyframes ellipse-mobile-2 {
        0% {
            height: 0;
        }

        20% {
            height: 0;
        }

        35% {
            height: 69%;
        }

        55% {
            height: 69%;
        }

        70% {
            height: 69%;
        }

        95% {
            height: 0;
        }

        100% {
            height: 0;
        }
    }

    @keyframes ellipse-mobile-3 {
        0% {
            height: 0;
        }

        20% {
            height: 0;
        }

        40% {
            height: 69%;
        }

        55% {
            height: 69%;
        }

        80% {
            height: 69%;
        }

        90% {
            height: 0;
        }

        100% {
            height: 0;
        }
    }

}
