@layer base {
    html {
        @apply scroll-smooth scroll-pt-24 lg:scroll-pt-36;
    }

    body {
        @apply box-border overflow-x-hidden dark:bg-black text-20 dark:text-white font-david font-regular md:text-25 xl:text-30 xxxl:text-35;
    }
}

@layer utilities {
    [x-cloak] {
        display: none !important;
    }

    .no-scroll-on-mobile {
        @apply overflow-hidden lg:overflow-y-visible;
    }

    .text-content-secondary {
        @apply text-15 font-monument md:text-16 xl:text-17 xxxl:text-18;
    }

    .text-shadow {
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    }

    .h-fit {
        height: fit-content;
    }
}
