.PartnerList {
    .line-bottom {
        @apply relative;

        &::after {
            content: "";

            @apply absolute bottom-0 left-0 w-screen h-px bg-current;
        }
    }
}
