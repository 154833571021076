:root {
    --cc-primary-color: theme("colors.black");
    --cc-text-color: theme("colors.black");
    --cc-text-color-headline: theme("colors.black");
    --cc-modal-box-background: theme("colors.white");
    --cc-border-color: theme("colors.black");
    --cc-switch-bg-inactive: rgba(0, 0, 0, 0.4);
    --cc-background: rgba(20, 20, 20, 0.6);
}

@import "@AccWebsiteBundle/GdprConsent/GdprConsent";

.consent-content {
    .script-item-info {
        @apply text-[14px] leading-[1.5] text-black font-monument bg-transparent lg:text-15;
    }

    .script-actions {
        @apply flex flex-col md:gap-4;

        .script-actions-btn {
            @apply ml-0 px-8 py-3 text-15 text-black font-monument bg-transparent text-center uppercase border border-black rounded-full md:px-8 md:py-3.5 md:text-16 lg:py-4.5 lg:border-2 xl:text-17 xxl:py-5 xxxl:text-18 xxxl:py-5.5;

            &.is-primary {
                @apply bg-black text-white;
            }
        }
    }
}

button.GdprToggle {
    @apply max-lg:button w-full inline-block text-center font-monument uppercase lg:w-auto lg:underline lg:underline-offset-2 lg:hover:no-underline;
}

.gdpr-wrap > .gdpr-text {
    p {
        @apply py-2.5 lg:py-3;
    }

    div {
        @apply text-content-secondary;
    }

    > button.gdpr-show {
        @apply mt-4 lg:mt-8;
    }
}

.blog-iframe .gdpr-wrap {
    @apply lg:min-h-52 xxl:min-h-56 max:min-h-72;
}
