.hover-underline {
    @apply relative w-fit;

    &::after {
        content: "";

        @apply absolute left-0 bottom-0 right-0 bg-current scale-x-0 origin-right h-0.5 transition-transform duration-300 ease-in-out;
    }

    &:hover::after {
        @apply scale-x-100 origin-left;
    }
}
