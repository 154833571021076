@layer utilities {
    @keyframes loop {
        to {
            transform: translate(-50%);
        }
    }

    .ticker-inner {
        @apply flex flex-nowrap w-max;
        animation: loop 10s linear infinite;

        &[data-direction="right"] {
            animation-direction: reverse;
        }
    }
}