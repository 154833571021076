.wysiwyg {
    @apply flex flex-col;

    strong {
        @apply dark:text-white/70 font-regular;
    }

    a {
        @apply font-regular underline;
    }

    .button-wrapper a {
        @apply button inline-block no-underline;
    }

    ul,
    ol {
        @apply flex flex-col gap-y-2 md:gap-y-1 lg:gap-y-0.5;
    }

    ul li {
        @apply list-disc ml-5 py-0.5 md:ml-7 xl:ml-8 xxxl:ml-10;
    }

    ol li {
        @apply list-decimal ml-6 py-0.5 md:ml-8 xl:ml-9 xxxl:ml-11;
    }

    table,
    th,
    td {
        @apply py-1.5 px-2 border text-16 border-solid dark:border-white;
        background-color: transparent !important;
    }

    table {
        @apply block p-0 border-none flex-[0_0_100%] overflow-x-auto;
    }
}
